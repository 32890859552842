<template>
	<v-form ref="form" @submit.prevent="submit">
	<v-row>
		<v-col cols="12"><h3>PONCHES MANUALES</h3></v-col>
			<v-col md="4">
				<v-text-field v-model="date" type="date" label="Fecha" :rules="rules" hint="mm/dd/yyyy" prepend-inner-icon="mdi-calendar" class="mb-3 rounded-lg" outlined dense required persistent-hint></v-text-field>
				<v-text-field v-model="time" type="time" label="Hora" :rules="rules" prepend-inner-icon="mdi-clock-time-four-outline" class="rounded-lg" outlined dense required></v-text-field>
				<v-btn :disabled="loading" color="#eee" type="submit" class="border rounded-lg" depressed>Ponchar</v-btn>
			</v-col>
			<v-col md="4">
				<v-autocomplete v-model="card_id" :items="employees" item-text="name" item-value="card_id" :rules="rules" class="rounded-lg" ref="card_id" :disabled="loading" prepend-inner-icon="mdi-magnify" label="Empleado" outlined dense></v-autocomplete>
			</v-col>
		</v-row>
	</v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import { get_current_date, time_to_12hr } from '@/assets/modules'

export default {
	name: 'ManualTimestamps',
	data: () => ({
		card_id: '',

		loading: false,

		date: null,
		time: (new Date()).toLocaleTimeString('en-US', { hour12: false }),

        rules: [v => !!v || 'Campo es requerido'],
	}),
	mounted() {
		this.date = this.get_current_date()
		this.$refs['card_id'].$refs.input.select()
	},
	methods: {
		get_current_date, time_to_12hr,
		submit() {
            if (!this.$refs.form.validate()) return
			this.loading = true
			this.$http.post('/timestamps', this.args).then(() => {
				this.card_id = ''
				this.$refs.form.resetValidation()
				this.$refs['card_id'].$refs.input.select()
			}).finally(() => {
				this.loading = false
			})
		}
	},
	computed : {
		...mapGetters(['employees']),
		args() {
			return {
				card_id: this.card_id,
				date: this.date,
				time: this.time_to_12hr(this.time)
			}
		}
    }
}
</script>
